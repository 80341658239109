.content.app-content {
    background: #eef3f9 !important;
}

.dark-layout .content.app-content {
    background: #161d31 !important;
}

.footer-light {
    background: #eef3f9 !important;
}

.dark-layout .footer-light {
    background: #161d31 !important;
}

.main-menu.menu-light {
    background: #eef3f9 !important;
}

.main-menu.menu-light .navigation {
    background: #eef3f9 !important;
}

.main-menu .shadow-bottom {
    margin-top: -0.7rem;
    background: linear-gradient(#eef3f9 41%, hsla(213, 48%, 95%, 0.11) 95%, hsla(213, 48%, 95%, 0));
}

.light-layout .navbar-floating .header-navbar-shadow {
    background: linear-gradient(180deg, hsla(213, 48%, 95%, 0.95) 44%, hsla(213, 48%, 95%, 0.46) 73%, hsla(0deg, 0%, 100%, 0));
    left: 0 !important;
}

.navbar-floating {
    // Floating nav should have same padding across all screen
    .navbar-container:not(.main-menu-content) {
        padding: 0.8rem 1rem;
    }
    .header-navbar-shadow {
        display: block;
        background: linear-gradient( 180deg, hsla(213, 48%, 95%, 0.95) 44%, hsla(213, 48%, 95%, 0.46) 73%, hsla(0, 0%, 100%, 0));
        padding-top: 2.2rem;
        background-repeat: repeat;
        width: 100%;
        height: 102px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
}

.dark-layout .header-navbar-shadow {
    background: linear-gradient(180deg, rgba(22, 29, 49, 0.9) 44%, rgba(22, 29, 49, 0.43) 73%, rgba(22, 29, 49, 0)) !important;
}

.button-inline-spacing-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    &>* {
        margin-right: 0.15rem;
        margin-top: 0.51em;
    }
}

.navbar-light {
    background: #eef3f9;
}

.header-navbar.navbar-shadow {
    box-shadow: none !important;
}

.main-menu.menu-light .navigation>li .active {
    background: #d3e3fd;
    color: #000;
    font-weight: 500;
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    -webkit-transition: border-radius .15s cubic-bezier(0.4, 0, 0.2, 1), margin .15s cubic-bezier(0.4, 0, 0.2, 1), width .15s cubic-bezier(0.4, 0, 0.2, 1);
    transition: border-radius .15s cubic-bezier(0.4, 0, 0.2, 1), margin .15s cubic-bezier(0.4, 0, 0.2, 1), width .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-menu.menu-light .navigation li a {
    font-size: 1rem;
    color: #202124;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
    background-color: #bed7ff !important;
    border-color: #bed7ff !important;
    color: #000 !important;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
    color: #333 !important;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow {
    background-color: #343d55 !important;
    border-color: #3b4253 !important;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
    color: #b4b7bd !important;
}

.card {
    box-shadow: none !important;
}

.main-menu.menu-light .navigation>li .active {
    background: #d3e3fd;
    box-shadow: inset 0 0 0 1px transparent;
}

.vertical-layout .main-menu .navigation a.active {
    background: #d3e3fd;
}

.vertical-layout .main-menu .navigation a.active {
    box-shadow: none !important;
    border-radius: 4px;
}

.main-menu.menu-dark .navigation>li .active {
    background: #171c2e;
    box-shadow: inset 0 0 0 1px transparent;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: rgba(221, 233, 240, 0.485);
    // opacity: 0.9;
}

.dark-layout .table thead th,
.dark-layout .table tfoot th {
    background-color: rgba(221, 233, 240, 0.485) !important;
}

.table thead th,
.table tfoot th {
    background-color: #d3e3fd !important;
}

.table td,
.table th {
    padding-left: 5px;
    padding-right: 5px;
}

.display-6 {
    font-size: calc(1.225rem + 1.1vw);
    font-weight: 400;
    line-height: 1.2;
}

.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.9);
}

.dark-layout .react-confirm-alert-overlay {
    background: rgba(46, 46, 46, 0.941);
}

.dark-layout .ql-toolbar.ql-snow {
  border: 1px solid #111 !important;
}

.dark-layout .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #111;
}
.dark-layout .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #111;
}

.dark-layout .ql-container.ql-snow {
  border: 1px solid #111;
}

@media (pointer: coarse) {
    .dark-layout .ql-snow.ql-toolbar button:hover:not(.ql-active),
    .dark-layout .ql-snow .ql-toolbar button:hover:not(.ql-active) {
      color: #cdcdcd;
    }
    .dark-layout .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .dark-layout .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .dark-layout .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
    .dark-layout .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
      fill: #cdcdcd;
    }
    .dark-layout .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .dark-layout .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .dark-layout .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
    .dark-layout .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
      stroke: #cdcdcd;
    }
  }

.dark-layout .ql-snow .ql-stroke {  
  stroke: #cdcdcd;  
}
.dark-layout .ql-snow .ql-stroke-miter {  
  stroke: #cdcdcd;
}
.dark-layout .ql-snow .ql-fill,
.dark-layout .ql-snow .ql-stroke.ql-fill {
  fill: #cdcdcd;
}

.dark-layout .ql-toolbar.ql-snow .ql-picker-label {  
  color: #cdcdcd;
}
